<template>
	<div class="layout">
		<main>
			<StoreInit
				v-if="!storesReady"
				v-model:model-stores-ready="storesReady" />

			<template v-if="redirectUri || !storesReady">
				<PageLoader />
			</template>

			<template v-else>
				<div class="layout-aside">
					<div class="layout-aside__menu">
						<AsideMenu />
					</div>

					<div class="layout-aside__main">
						<slot />
					</div>
				</div>
			</template>
		</main>
	</div>
</template>

<script setup>
	import vSelect from "~/vendors/vue-select-modified/vue-select-modified";

	const storesReady = useState("stores-ready", () => !authorized());

	vSelect.props.components.default = () => ({
		Deselect: {
			render: () => h("span", ""),
		},
		OpenIndicator: {
			render: () => h("span", ""),
		},
	});

	const redirectUri = ref(useRoute().query.redirect_uri);
</script>
